import { ButtonCta } from '@components/UI/Button/ButtonCta'
import styled from '@mui/material/styles/styled'
import { TeaserOverlayStyle, TOverlayTextAlign } from '@typesApp/teaser'
import { teaserBackGroundColorToBg, teaserOverlayStyleToColor } from '@utils/placements'

export const StyledWrapperPromotionBar = styled('div', {
  name: 'PromotionBarWrapper',
  shouldForwardProp: prop => prop !== 'promoBarBackground',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  backgroundColor: theme.palette.background[9],
  color: theme.palette.neutral.main,
}))

export const StyledContentPromotionBar = styled('div', {
  name: 'PromotionBarContent',
})(() => ({
  height: 'inherit',
  width: 'inherit',
  display: 'flex',
}))

export const StyledContentSlide = styled('div', {
  name: 'PromotionBarContentSlide',
  shouldForwardProp: prop => prop !== 'bgColor',
})<{ bgColor?: string }>(({ bgColor, theme }) => ({
  ...teaserBackGroundColorToBg(bgColor, theme),
  width: '100vw',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'row',
  height: 'inherit',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(7.5),
  padding: theme.spacing(3, 0),
}))

export const StyledTextPromotionBar = styled('div', {
  name: 'TextPromotionBar',
  shouldForwardProp: (prop: string) => !['textAlign', 'hasCallToAction', 'textColor'].includes(prop),
})<{
  textColor?: TeaserOverlayStyle
  textAlign?: TOverlayTextAlign
  hasCallToAction?: boolean
}>(({ textColor = 'text-dark-primary', textAlign = 'center', hasCallToAction = false, theme }) => ({
  textAlign,
  ...teaserOverlayStyleToColor(theme, textColor),
  [theme.breakpoints.down('sm')]: {
    marginBottom: hasCallToAction ? 0 : 'inherit',
  },
}))

export const StyledCountdownContainer = styled('div', {
  name: 'PromotionBarCountdownContainer',
})(() => ({
  fontSize: 12,
  fontWeight: 600,
  textAlign: 'center',
  margin: 0,
}))

export const StyledDiscountLabel = styled('span', {
  name: 'PromotionBarDiscountLabel',
})(() => ({
  fontWeight: 600,
}))

export const StyledPromotionBarButton = styled(ButtonCta, {
  name: 'PromotionBarButton',
})(() => ({
  height: '28px',
  width: 'auto',
}))
