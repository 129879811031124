import ButtonUnstyled, { ButtonUnstyledProps } from '@mui/base/ButtonUnstyled'
import { styled, Theme } from '@mui/material/styles'
import { ITeaserCallToAction } from '@typesApp/cmsPlacement/LXTeaser'
export interface GenericButtonProps {
  theme?: Theme
  labelText?: string
  loading?: boolean
}
export type VariantType = ITeaserCallToAction['style']
export type ButtonProps = GenericButtonProps & ButtonUnstyledProps & { variant?: VariantType }

interface VariantProps {
  background: string
  hoverBackground: string
  color: string
  colorHover: string
  borderColor: string
  borderColorhover: string
  padding: string
  width?: string
}

const createVariant = (theme: Theme, variantOverrides: Partial<VariantProps> = {}): VariantProps => {
  const defaultProps: VariantProps = {
    background: 'transparent',
    hoverBackground: 'transparent',
    color: theme.palette.common.black,
    colorHover: theme.palette.common.black,
    borderColor: 'transparent',
    borderColorhover: 'transparent',
    padding: theme.spacing(0, 8),
    width: 'auto',
  }

  return { ...defaultProps, ...variantOverrides }
}

export const variantMap = (theme: Theme) => {
  const baseVariant = (
    background: string,
    hoverBackground: string,
    color: string,
    colorHover: string,
    borderColor: string,
    borderColorhover: string
  ) => ({
    background,
    hoverBackground,
    color,
    colorHover,
    borderColor,
    borderColorhover,
  })

  const variants = {
    primaryFill: baseVariant(
      theme.palette.primary.main,
      theme.palette.primary.tones[50],
      theme.palette.common.white,
      theme.palette.common.white,
      theme.palette.primary.main,
      theme.palette.primary.tones[50]
    ),
    secondaryFill: baseVariant(
      theme.palette.success.main,
      theme.palette.success.tones[70],
      theme.palette.common.white,
      theme.palette.common.white,
      theme.palette.success.main,
      theme.palette.success.main
    ),
    primaryOutline: baseVariant(
      'transparent',
      theme.palette.primary.tones[95],
      theme.palette.primary.main,
      theme.palette.primary.main,
      theme.palette.neutral.tones[60],
      theme.palette.neutral.tones[60]
    ),
    secondaryOutline: baseVariant(
      'transparent',
      theme.palette.success.tones[99],
      theme.palette.success.tones[40],
      theme.palette.success.tones[40],
      theme.palette.neutral.tones[60],
      theme.palette.neutral.tones[60]
    ),
    primaryFillDark: baseVariant(
      theme.palette.primary.tones[90],
      theme.palette.primary.tones[80],
      theme.palette.secondary.main,
      theme.palette.secondary.main,
      theme.palette.primary.tones[90],
      theme.palette.primary.tones[80]
    ),
    primaryOutlineDark: baseVariant(
      'transparent',
      theme.palette.primary.tones[90],
      theme.palette.primary.tones[90],
      theme.palette.neutral.main,
      theme.palette.neutral.tones[60],
      theme.palette.neutral.tones[60]
    ),
  }

  const createTextButtonVariant = (background: string, color: string, colorHover: string, hoverBackground: string) => ({
    background,
    color,
    colorHover,
    hoverBackground,
  })

  const textButtonVariants = {
    primary: createTextButtonVariant(
      'transparent',
      theme.palette.primary.main,
      theme.palette.primary.main,
      theme.palette.primary.tones[95]
    ),
    secondary: createTextButtonVariant(
      'transparent',
      theme.palette.success.tones[40],
      theme.palette.success.tones[40],
      theme.palette.success.tones[95]
    ),
    primaryDark: createTextButtonVariant(
      'transparent',
      theme.palette.primary.tones[95],
      theme.palette.primary.main,
      theme.palette.primary.tones[80]
    ),
  }

  const sizes = ['small', 'fullwidth']

  const generateVariants = (baseName: string, variant: any) => ({
    [baseName]: createVariant(theme, variant),
    ...Object.fromEntries(sizes.map(size => [`${baseName}-${size}`, createVariant(theme, variant)])),
  })

  const buttonVariants = {
    ...generateVariants('cta-fill-primary', variants.primaryFill),
    ...generateVariants('cta-fill-secondary', variants.secondaryFill),
    ...generateVariants('cta-fill-primary-dark', variants.primaryFillDark),
    ...generateVariants('cta-outline-primary', variants.primaryOutline),
    ...generateVariants('cta-outline-secondary', variants.secondaryOutline),
    ...generateVariants('cta-outline-primary-dark', variants.primaryOutlineDark),
    ...generateVariants('cta-textbutton-primary', textButtonVariants.primary),
    ...generateVariants('cta-textbutton-secondary', textButtonVariants.secondary),
    ...generateVariants('cta-textbutton-primary-dark', textButtonVariants.primaryDark),
    ...generateVariants('cta-fill-fullwidth-primary', variants.primaryFill),
    ...generateVariants('cta-fill-fullwidth-secondary', variants.secondaryFill),
    ...generateVariants('cta-fill-fullwidth-primary-dark', variants.primaryFillDark),
    ...generateVariants('cta-outline-fullwidth-primary', variants.primaryOutline),
    ...generateVariants('cta-outline-fullwidth-secondary', variants.secondaryOutline),
    ...generateVariants('cta-outline-fullwidth-primary-dark', variants.primaryOutlineDark),
  }

  return buttonVariants
}

export const ButtonCta = styled(ButtonUnstyled)<ButtonProps>(({ theme, variant = 'cta-fill-primary' }) => {
  const styles = variantMap(theme)[variant]

  return {
    background: styles.background,
    color: styles.color,
    cursor: 'pointer',
    border: `1px solid ${styles.borderColor}`,
    borderRadius: theme.shape.borderRadius,
    padding: styles.padding,
    width: styles.width,
    transition: 'background-color 0.25s, color 0.25s, border-color 0.25s',
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      background: styles.hoverBackground,
      color: styles.colorHover,
      borderColor: styles.borderColorhover,
    },
  }
})
